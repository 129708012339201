import React from "react";

import "./footer.component.styles.scss";

const Footer = () => {
  return (
    <div className="lvs-footer">
      <div className="lvs-footer__container">
        <p>© 2020 Laviens. Tutti i diritti riservati.</p>
      </div>
    </div>
  );
};

export default Footer;

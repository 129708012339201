import React from "react";

import "./booking-page.component.styles.scss";
import { HeroImage } from "../../generic";
import Helmet from "react-helmet";

const BookingPage: React.FC<any> = () => {
  return (
    <div>
      <Helmet>
        <title>Prenota un momento di benessere con Laviens</title>
        <meta
          name="description"
          content="Prenota Laviens, per regalarti il benessere che meriti. I nostri massaggi sono di altissima qualità, massaggio rilassante, decontratturante, sportivo e drenante orientati al puro benessere"
        />
        <meta
          name="keywords"
          content="massaggi, massaggio, relax, rilassante, decontratturante, drenante, sportivo, californiano, olistico, studio, privato, olio ,essenziale, cervicale, lombalgia, anticellulite, drenaggio"
        />
      </Helmet>
      <HeroImage
        title="Prenota,"
        subtitle="Solo cosi proverai il benessere che meriti."
        imageUrl={
          "https://images.pexels.com/photos/1549280/pexels-photo-1549280.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        }
      />
      <h2 style={{ margin: "0 auto", marginTop: "50px", width: "600px" }}>
        Prenota un appuntamento con noi chiamando <br /> al numero +39
        3492468186. Massima serietà e professionalità, in studio privato tutti i
        giorni feriali dalle 8:00 alle 18:00 e festivi dalle 09:00 alle 20:00.
      </h2>
    </div>
  );
};

export default BookingPage;

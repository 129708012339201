import React from "react";

import "./header.component.styles.scss";

import { Toolbar /*, DrawerMenu */ } from "../../navigations";
import { LaviensLogo } from "../../generic";

const Header = () => {
  return (
    <div className="lvs-header">
      <div className="lvs-header__logo-container">
        <LaviensLogo />
      </div>
      <span className="spacer" />
      <div className="lvs-header__toolbar-container">
        <Toolbar />
      </div>
    </div>
  );
};

export default Header;

/*
 <div className="lvs-header__drawer-menu-container">
        <DrawerMenu />
      </div>
*/

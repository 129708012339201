import "core-js"; // used to solve incompatibility issues with Internet explorer
import "isomorphic-unfetch"; // used to solve an apollo graphql incompatibility issue with Internet Explorer

import React from "react";
import { hydrate, render } from "react-dom";

import Main from "./components/main.component";
import * as serviceWorker from "./serviceWorker";

const rootElement: any = document.getElementById("root");

rootElement.hasChildNodes()
  ? hydrate(<Main />, rootElement)
  : render(<Main />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { Helmet } from "react-helmet";

import "./home-page.component.styles.scss";
import { HeroImage } from "../../generic";
import { DefaultSection } from "../../sections";

import image from "../../../assets/images/candles-glow-with-spa-decor.jpg"; 


const HomePage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Massaggi, Benessere a Firenze | Laviens</title>
        <meta
          name="description"
          content="Cerchi Massaggi a Firenze? Laviens: Massaggi a Firenze, scopri su Laviens i migliori massaggi e trattamenti legati al mondo del benessere."
        />
        <meta
          name="keywords"
          content="massaggi, massaggi firenze, massaggi sesto fiorentino, massaggio, estetista, skin care, benessere"
        />
      </Helmet>
      <HeroImage
        title="Laviens,"
        subtitle="Massaggi & Benessere a Firenze."
        buttonText={"Prenota una sessione con noi."}
        imageUrl={image}
      />
      <DefaultSection
        imageUrl={
          "https://images.pexels.com/photos/3230236/pexels-photo-3230236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        }
        orientation="left"
        title="Massaggi Olistici"
        description="Fare un massaggio è un modo naturale di aver prestazioni migliori nella vita, poter vivere senza dolore e aumentare il benessere generale."
        buttonText="Ho bisogno di un massaggio."
      />
      <DefaultSection
        imageUrl={
          "https://images.pexels.com/photos/3762635/pexels-photo-3762635.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        }
        orientation="right"
        title="Skin Care"
        description="Prendersi cura della nostra pelle non solo è una coccola, ma una precauzione necessaria per mantenerla bella e sana."
        buttonText="Amo la mia pelle, la voglio curare adesso."
      />
      <DefaultSection
        imageUrl={
          "https://images.unsplash.com/photo-1515377905703-c4788e51af15?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
        }
        orientation="left"
        title="Aromaterapia"
        description="L'aromaterapia offre una potente combinazione del tocco premuroso del massaggio e l'uso di oli essenziali naturali puri attraverso due sensi primordiali, il tatto e l'olfatto
        Il trattamento può aiutare le persone fisicamente, mentalmente ed emotivamente a trovare armonia, equilibrio e provare un profondo senso di benessere."
        buttonText="Voglio provare questo benessere."
      />
    </div>
  );
};

export default HomePage;

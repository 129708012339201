import React from "react";
import { HeroImage } from "../../generic";
import { Icon } from "antd";
import Helmet from "react-helmet";

const ContactPage = () => {
  return (
    <div>
      <Helmet>
        <title>Contatti Laviens - Scopri le nostre Soluzioni di Benessere.</title>
        <meta
          name="description"
          content="Scopri le nostre Soluzioni di Benessere. I nostri massaggi sono di altissima qualità, massaggio rilassante, decontratturante, sportivo e drenante orientati al puro benessere"
        />
        <meta
          name="keywords"
          content="massaggi, massaggio, relax, rilassante, decontratturante, drenante, sportivo, californiano, olistico, studio, privato, olio ,essenziale, cervicale, lombalgia, anticellulite, drenaggio"
        />
      </Helmet>
      <HeroImage
        title="Entra in contatto con noi,"
        subtitle="Scoprirai le soluzioni di benessere più adatte a te."
        imageUrl={
          "https://images.pexels.com/photos/4970/fashion-person-woman-hand.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        }
      />
      <h2 style={{ margin: "0 auto", marginTop: "50px", width: "600px" }}>
        <Icon type="phone" /> +39 3492468186
        <br /> <Icon type="environment" /> Sesto Fiorentino (Firenze)
      </h2>

      <div style={{ margin: "0 auto", marginTop: "25px", width: "600px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5756.135843175193!2d11.1806227!3d43.8336896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a57cf6de24ba3%3A0xd35c89d09e25597e!2sStudio%20Olistico%20-%20Laviens%20Regina!5e0!3m2!1sen!2sit!4v1580417742023!5m2!1sen!2sit"
          width="600"
          height="450"
          title="maps"
        />
      </div>
    </div>
  );
};
export default ContactPage;

import React from "react";
import { Switch, Route } from "react-router";

import "./content.component.styles.scss";
import {
  HomePage,
  AboutPage,
  BookingPage,
  ContactPage,
  NotFoundPage,
  TreatmentsPage
} from "../../pages";

const Content: React.FC = () => {
  return (
    <div className="lvs-content">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/booking" component={BookingPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/treatments" component={TreatmentsPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default Content;

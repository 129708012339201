import React from "react";

import "./default-section.component.styles.scss";
import { Link } from "react-router-dom";
import { Button } from "antd";

interface IDefaultSectionProps {
  imageUrl: string;
  title?: string;
  description?: string;
  buttonText?: string;
  orientation: "left" | "right";
}

const LeftOriented = ({ imageUrl, title, description, buttonText }: any) => (
  <>
    <div className="default-section__content">
      <h2>{title}</h2>
      <p>{description}</p>
      <Link
        to="/booking"
        title="Laviens - Prenota un momento di benessere con noi."
      >
        <Button size="large" type="primary">
          {buttonText}
        </Button>
      </Link>
    </div>
    <div
      className="default-section__image"
      style={{
        background: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginLeft: "100px"
      }}
    />
  </>
);

const RightOriented = ({ imageUrl, title, description, buttonText }: any) => (
  <>
    <div
      className="default-section__image"
      style={{
        background: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginRight: "100px"
      }}
    />
    <div className="default-section__content">
      <h2>{title}</h2>
      <p>{description}</p>
      <Link
        to="/booking"
        title="Laviens - Prenota un momento di benessere con noi."
      >
        <Button size="large" type="primary">
          {buttonText}
        </Button>
      </Link>
    </div>
  </>
);

const DefaultSection: React.FC<IDefaultSectionProps> = ({
  imageUrl,
  orientation,
  title,
  description,
  buttonText
}) => {
  return (
    <div
      className="default-section"
      itemProp="service"
      itemScope
      itemType="http://schema.org/Service"
    >
      {orientation === "left" ? (
        <LeftOriented
          imageUrl={imageUrl}
          title={title}
          description={description}
          buttonText={buttonText}
        />
      ) : (
        <RightOriented
          imageUrl={imageUrl}
          title={title}
          description={description}
          buttonText={buttonText}
        />
      )}
    </div>
  );
};

export default DefaultSection;

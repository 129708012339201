import React from "react";
import "./hero-image.component.styles.scss";

import { Button } from "antd";
import { Link } from "react-router-dom";

interface IHeroImageProps {
  imageUrl: string;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  customStyle?: any;
}

const HeroImage: React.FC<IHeroImageProps> = ({
  title,
  subtitle,
  imageUrl,
  buttonText,
  customStyle
}) => {
  return (
    <div
      className="lvs-hero-image"
      style={{
        background: `url(${imageUrl})`,
        height: "400px",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...customStyle
      }}
    >
      <div className="lvs-hero-image__content">
        <div className="lvs-hero-image__content--display">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          <Link
            to="/booking"
            title="Prenota un momento di benessere con Laviens"
          >
            {buttonText ? (
              <Button size="large" type="dashed">
                {buttonText}
              </Button>
            ) : null}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default HeroImage;

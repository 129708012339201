import React from "react";
import { Link } from "react-router-dom";
// import logo from "../../../assets/icons/lotus.svg";
import "./laviens-logo.component.styles.scss";

/*
      <img
        src={logo}
        alt="icon made by https://www.flaticon.com/authors/good-ware"
        width="20"
      />
*/
const LaviensLogo = () => {
  return (
    <Link to={"/"} title="Laviens, Massaggi a Firenze - Dove il tuo benessere è la nostra priorità">
     <h3>LAVIENS</h3>
    </Link>
  );
};

export default LaviensLogo;

const ToolbarSettings = [
  {
    key: "treatments",
    label: "Trattamenti",
    page: "treatments",
    title: "Trattamenti Laviens, con noi troverai le soluzioni per il tuo benessere."
  },
  /*{
    key: "gift_card",
    label: "Regala Laviens",
    page: "contact"
  },
  {
    key: "about",
    label: "Chi siamo",
    page: "about"
  },*/
  {
    key: "contact",
    label: "Contatti",
    page: "contact",
    title: "Contatti Laviens - Scopri le nostre Soluzioni di Benessere."
  },
  {
    key: "booking",
    label: "Prenota",
    page: "booking",
    title: "Laviens - Prenota un momento di benessere con noi."
  }
];

export default ToolbarSettings;

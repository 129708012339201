import React from "react";

import "./not-found-page.component.styles.scss";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

const NotFoundPage: React.FC = () => {
  return (
    <div className="lvs-not-found-page">
      <Helmet>
        <title>Laviens: Pagina non trovata</title>
        <meta
          name="description"
          content="Studio Olistico Laviens, dove il vostro benessere è la nostra priorità. I nostri massaggi sono di altissima qualità, massaggio rilassante, decontratturante, sportivo e drenante orientati al puro benessere"
        />
        <meta name="keywords" content="massaggi, massaggi firenze, massaggio, estetista, skin care, benessere" />
      </Helmet>
      <Result
        status="error"
        title="404"
        subTitle="Oops, pagina non trovata!"
        extra={
          <Link
            to="/"
            title="Laviens, Massaggi a Firenze - Dove il tuo benessere è la nostra priorità"
          >
            <Button type="primary">Torna alla pagina principale</Button>
          </Link>
        }
      />
    </div>
  );
};

export default NotFoundPage;

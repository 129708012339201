import React from "react";
import "./main.component.styles.scss";
import { Header, Content, Footer } from "./layout";
import { BrowserRouter } from "react-router-dom";

const Main: React.FC = () => {
  return (
    <BrowserRouter>
      <div /*style={{ minWidth: "1280px" }}*/>
        <Header />
        <Content />
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default Main;

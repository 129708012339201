import React from "react";
import "./treatments-page.component.styles.scss";
import { HeroImage } from "../../generic";
import Helmet from "react-helmet";
import { Tabs, List } from "antd";

const { TabPane } = Tabs;

const data: any = [
  "Classico Svedese",
  "Californiano",
  "Emozionale",
  "Decontratturante",
  "Anticellulite",
  "Linfodrenante"
];

const dataSkinCare: any = [
  "Trattamenti Viso & Corpo",
  "Epilazione totale",
  "Epilazione parziale",
  "Epilazione intima alla brasiliana per ambosessi"
];
const TreatmentsPage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>
          Trattamenti Laviens, con noi troverai le soluzioni per il tuo
          benessere
        </title>
        <meta
          name="description"
          content="Trattamenti Laviens, qui troverai le soluzioni per il tuo benessere. I nostri massaggi sono di altissima qualità, massaggio rilassante, decontratturante, sportivo e drenante orientati al puro benessere"
        />
        <meta
          name="keywords"
          content="massaggi, massaggio, relax, rilassante, decontratturante, drenante, sportivo, californiano, olistico, studio, privato, olio ,essenziale, cervicale, lombalgia, anticellulite, drenaggio"
        />
      </Helmet>
      <HeroImage
        title="Trattamenti,"
        subtitle="Qui troverai le soluzioni per il tuo benessere"
        imageUrl={
          "https://images.pexels.com/photos/3673941/pexels-photo-3673941.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        }
      />
      <Tabs defaultActiveKey="1" size={"large"} onChange={() => console.log()} tabPosition="top">
        <TabPane tab="Massaggi" key="1">
          <List
            size="large"
            bordered
            dataSource={data}
            renderItem={(item: any) => <List.Item>{item}</List.Item>}
          />
        </TabPane>
        <TabPane tab="Skin Care" key="2">
          <List
            size="large"
            bordered
            dataSource={dataSkinCare}
            renderItem={(item: any) => <List.Item>{item}</List.Item>}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default TreatmentsPage;

import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import "./toolbar.component.styles.scss";
import ToolbarSettings from "../../../settings/toolbar.settings";

const Toolbar: React.FC = () => {
  const { pathname } = useLocation();
  const [selectedKey, setSelectedKey] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleClick = (e: any) => setSelectedKey(e.key);

  return (
    <div className="lvs-toolbar">
      <Menu
        onClick={handleClick}
        selectedKeys={[selectedKey]}
        mode="horizontal"
        className="lvs-toolbar"
      >
        {ToolbarSettings.map(({ key, label, page, title }) => (
          <Menu.Item key={key}>
            <Link to={page} title={title}>
              {label}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

/*
     <Menu.Item key="login">
          <Button type="default" shape="circle" icon="login" />
        </Menu.Item>
*/

export default Toolbar;
